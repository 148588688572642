export default {
  app_ame: "Atmos Forms",
  locale: "en",
  locales: { en: "EN" },
  githubAuth: null,
  notion: { worker: "https://notion-forms-worker.notionforms.workers.dev/v1" },
  links: {
    help_url: "https://velocimatic.com/support",
    github_url: "velocimatic.com/support",
    github_forum_url: "velocimatic.com/support",
    discord: "https://velocimatic.com/support",
    twitter: "https://twitter.com/",
    zapier_integration:
      "https://zvelocimatic.com/support",
    book_onboarding: "https://velocimatic.com/support",
    feature_requests: "https://velocimatic.com/support/",
    changelog_url: "https://velocimatic.com/support",
    roadmap: "https://velocimatic.com/support",
    tech_docs: "https://velocimatic.com/support",
  },
}
